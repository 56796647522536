import React from 'react';
import { ReactComponent as  InnovationLogo } from '../../assets/images/logo.svg';
import useWindowSize from '../../hooks/useWindowSize';

const Navbar = () => {
  const  { width } = useWindowSize();

  return (
    <div className="container-fluid">
      <div className="container d-flex justify-content-between align-items-center">
       <div>
         <InnovationLogo
           width= { width < 700 ? "84px" : "113px" }
           height={ width < 700 ? "60px" : "80px" }
         />
       </div>
       <div className="d-md-block d-none">
         <a href="#contact" className="btn btn-outline-primary rounded-3">
           Contact us
         </a>
       </div> 
      </div>
    </div>
  );
};

export { Navbar };

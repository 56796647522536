import './index.scss';
import ReactDOM from 'react-dom';
import { FullPage } from './components';
import React, { lazy, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = lazy(() => import("./App"));
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<FullPage />}>
      <App />
    </Suspense>
    <ToastContainer />
  </React.StrictMode>,
  document.getElementById('root')
);

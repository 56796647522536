import React from "react";
import Lottie from "lottie-react";
import InfinityAnimation from "../../assets/lottie/infinity.json";

const FullPage = () => {
  return (
    <div className="container-fluid bg-link" style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
      <div className="container w-100 h-100 d-flex justify-content-center align-items-center">
        <div className="w-25 h-25 d-flex justify-content-center align-items-center flex-column">
          <Lottie animationData={InfinityAnimation} />
          <p className="text-muted fw-bold font-monospace" style={{ letterSpacing: "0.2rem" }}>Loading...</p>
        </div>
      </div>
    </div>
  )
};

export { FullPage };
